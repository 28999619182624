.producto-card{
    background-color: white;
    min-height: 320px;
    padding: 10px;
}
.product-card:hover .contenedorBotonesProducto {
    visibility: visible;
}
.contenedorBotonesProducto{
    visibility: hidden;
}