*{
  font-family: Robotto;
}
img{
  height:100%;
}
.contenedor-principal{
  overflow-x: hidden;
}
li {
  list-style: none;
}

a {
  color: black;
}
a:hover{
  text-decoration: none;
  color: black;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.enlace-header {
  font-size: 15px;
  text-align: center;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  color: black!important;
}

.enlace-header:hover {
  text-decoration: underline;
  -webkit-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1)
}

.activo{
  text-decoration: underline;
  -webkit-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}
#navbar {
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  /* position: fixed; */
}



.formulario-login {
  -webkit-box-shadow: 1px 2px 3px #ddd, 2px 3px 4px #ddd;
  box-shadow: 1px 2px 3px #ddd, 2px 3px 4px #ddd;
  padding: 15px;
}

.contenedor-compra {
  background-color: #eee;
  padding: 15px;
  -webkit-box-shadow: 0px 1px 2px #ccc, 1px 2px 3px #ccc, 3px 4px 5px #ccc;
  box-shadow: 0px 1px 2px #ccc, 1px 2px 3px #ccc, 3px 4px 5px #ccc;
}

.tarjeta {
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  margin-bottom: auto;
  margin-top: auto;
}

.imagen-trabajador {
  background-image: url('https://i.ibb.co/9cyDzQQ/trabajador.webp');
  height: 100%;
  min-height: 400px;
  background-position: center;
  background-size: cover;
}

.icon {
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.icon:hover {
  -webkit-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}

.instagram {
  background-image: url('assets/socialMedia/001-instagram.png');
}

.facebook {
  background-image: url('assets/socialMedia/facebook.png');
}

.whatsapp {
  background-image: url('assets/socialMedia/whatsapp.png');
}

.gmail {
  background-image: url('assets/socialMedia/gmail.png');
}

.sidebar-box {
  border-radius: 5px;
  -webkit-box-shadow: 0px 1px 2px #eee, 1px 2px 3px #eee, 2px 3px 4px #eee;
  box-shadow: 0px 1px 2px #eee, 1px 2px 3px #eee, 2px 3px 4px #eee;
}

.producto {
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.producto:hover {
  -webkit-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}

.card-img {
  width: 50%;
}

.product-card {
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.product-card:hover {
  -webkit-box-shadow: 0px 1px 2px gray, 1px 2px 3px gray, 2px 3px 4px gray, 3px 4px 5px gray, 4px 5px 6px gray;
  box-shadow: 0px 1px 2px gray, 1px 2px 3px gray, 2px 3px 4px gray, 3px 4px 5px gray, 4px 5px 6px gray;
  border-radius: 5px;
  cursor: pointer;
}

@media (min-width:768px) {
  .enlace-header {
    font-size: 20px;
  }
}

@media (min-width:900px) {
  .tarjeta:hover {
    -webkit-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    cursor: pointer;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}