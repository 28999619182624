.contenedorHorario-subcontenedor div{
    line-height: 0px;
}

.contenedorHorario-subcontenedor{
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items:center;
}

.contenedorContacto{
    display:grid;
    grid-template-rows: repeat(2, 1fr);
}

.contenedorContacto-telefonos{
    line-height:0px;
}

.footer{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    padding: 2em;
    height:100%;
}

.contenedorHorario{
    display:grid;
    grid-template-rows: repeat(2, 1fr);
}