.dropdown{
    cursor:pointer;
}
.activo{
    text-decoration:underline !important;
    scale:1.1;
}

.logoZasCake{
    width:100%;
}

.contenedorLogoZasCake{
    align-items: center;
}

@media (max-width: 755px){
    .logoZasCake{
        width:50%;
    }
}