.header-local{
    min-height: 180px;
}
.contenedor-parallax img{
    filter:brightness(0.5) !important;
}
.texto-titulo{
    font-size:20px;
 
}

.local-background{
    background:url("https://www.toptal.com/designers/subtlepatterns/patterns/spikes.png");
}
@media(min-width:500px){
    .header-local{
        min-height:230px;
    }
}
@media(min-width:700px){
    .texto-titulo{
        font-size:25px;
    }
}
@media(min-width:800px){
    .header-local{
        min-height:300px;
    }
}

@media(min-width:1100px){
    .header-local{
        min-height: 350px;
    }
    .texto-titulo{
        font-size:2.5rem;
    }
}