.parallax-background img{
    filter:brightness(.5) !important;
    width:130% !important;
    height:280px !important;
}

@media(min-width:500px){
    .parallax-background img{
        height:350px !important;
    }
}


@media(min-width:700px){
    .parallax-background img{
        height:380px !important;
    }
}
@media(min-width:800px){
    .parallax-background img{
        height:440px !important;
    }
}
@media(min-width:938px){
    .parallax-background img{
        width: 100% !important;
        height: 500px !important;
    }
}
@media(min-width:1250px){
    .parallax-background img{
        height: 600px !important;
    }
}
@media(min-width:1600px){
    .parallax-background img{
        height: 675px !important;
    }
}
@media(min-width:1800px){
    .parallax-background img{
        height: 725px !important;
    }
}



.header-background{
    min-height:180px;
}

.box-contacto{
    padding:30px;
}

.box-formulario{
    background-color: #fffffff2;
    box-shadow:0px 1px 2px gray;
}

@media(min-width:500px){
    .header-background{
        min-height:230px;
    }
}

@media(min-width:800px){
    .header-background{
        min-height:300px;
    }
}

@media(min-width:1100px){
    .header-background{
        min-height: 350px;
    }
    
}