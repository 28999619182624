.home-background {
    min-height: 350px;
  }
  
.mod{
  width: 325px !important;
  padding-bottom:20px;
}

.imagen-home img{
    filter:brightness(.9) !important;
}
.second-container{
    width:100%;
}
.second-container img{
    width:100%;
    top:25%;
}




  @media(min-width:500px){
    .home-background{
      min-height:500px;
    }
    .mod{
      width: 350px !important;
    }
  }
   
  @media(min-width:700px){
    .mod{
      width: 450px !important;
    }
  }

  @media(min-width:1200px){
    .home-background{
      min-height:700px;
    }
  }
  
  
